import React from "react"
import Layout from "../components/layout"
import PageHeader from "../components/pageheader"
import SEO from "../components/seo"

const NotFoundPage = () => (
  <Layout>
    <PageHeader
      subTitle="Equity Alliance MN"
      headingTitle="404: Page Not Found"
      theme="about"
    />
    <SEO title="404: Not found" />
    <div className="container">
      {/*<h1>NOT FOUND</h1>*/}
      <p className="singlep">
        The page you are looking for was moved, removed, renamed, or might never
        existed.
      </p>
    </div>
  </Layout>
)

export default NotFoundPage
